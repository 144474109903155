import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

gsap.config({
  nullTargetWarn: false
})

export const initGsap = (pageName) => {


  const rsRevealAnimations = gsap.utils.toArray('.RS-animation');
  rsRevealAnimations.forEach((item) => {
    gsap.from(item, {
      scrollTrigger: {
        start: 'top bottom',
        end: 'bottom top',
        trigger: item,
        toggleClass: 'RS-animation--enter',
        once: true,
        scrub: 1,
        stagger: 5,
      }
    });
  });


  const rsLoopedAnimations = gsap.utils.toArray('.RS-looped-animation');

  rsLoopedAnimations.forEach(item => {
    gsap.from(item, {
      scrollTrigger: {
        start: 'top bottom',
        end: 'bottom top',
        trigger: item,
        toggleClass: 'RS-looped-animation--running',
        scrub: 1
      }
    });
  });
  

  const topNavBar = document.querySelector('.RS-top-bar');

  ScrollTrigger.create({
    start: 0,
    end: 99999,
    onUpdate: ({direction}) => {
      if (direction == -1) {
        topNavBar.classList.remove('RS-top-bar--hidden');
      } else {
        topNavBar.classList.add('RS-top-bar--hidden');
      }
    }
  });


  

  if(pageName === 'Home'){
    const aboutUserCards = gsap.utils.toArray("#RS-list--tiles-stacked li");
    const spacer = 32;
    let spacerMultiplier;

    if (window.innerWidth < 860) {
      spacerMultiplier = 4;
    } else {
      spacerMultiplier = 3; 
    }

    aboutUserCards.forEach((card, index) => {
      ScrollTrigger.create({
        trigger: card,
        start: `top-=${index * spacer  + spacer*spacerMultiplier} top`,
        endTrigger: '#RS-list--tiles-stacked',
        end: `bottom top+=${400 + (-1 * spacer)}`,
        pin: true,
        pinSpacing: false,
        markers: false,
        id: 'pin',
        invalidateOnRefresh: true,
      });
    });

    const aboutUserHeader = gsap.utils.toArray("#RS-header--tiles-stacked");
    let spacer2;

    if (window.innerWidth < 860) {
      spacer2 = 12;
    } else {
      spacer2 = 32; 
    }

  

    aboutUserHeader.forEach((card, index) => {
      ScrollTrigger.create({
        trigger: card,
        start: `top-=${index * spacer2  + spacer2*3} top`,
        endTrigger: '#RS-list--tiles-stacked',
        end: `bottom top+=${400 + (-1 * spacer)}`,
        pin: true,
        pinSpacing: false,
        markers: false,
        id: 'pin',
        invalidateOnRefresh: true,
      });
    });



    const headerDecors = gsap.utils.toArray('.RS-header--decor');

    headerDecors.forEach(item => {
      gsap.from(item, {
        scrollTrigger: {
          start: 'top bottom',
          end: 'bottom top',
          trigger: item,
          toggleClass: 'RS-header--decor-visible',
          scrub: 1
        }
      });
    });

    // const textHighlighters = gsap.utils.toArray('.RS-text-highlighter');

    // textHighlighters.forEach(item => {
    //   gsap.from(item, {
    //     scrollTrigger: {
    //       start: 'top bottom',
    //       end: 'bottom top',
    //       trigger: item,
    //       toggleClass: 'RS-text-highlighter--visible',
    //       scrub: 1
    //     }
    //   });
    // });

    ScrollTrigger.create({
      trigger: '#RS-section-intro',
      start: 'top top',
      end: 'center top',
      animation: gsap.fromTo('.RS-intro-product-mock-wrapper', {yPercent: 0, alpha: 1, scale: 1 }, {yPercent: -25, alpha: 1, scale: .98}),
      scrub: 1
    });

    
    // ScrollTrigger.create({
    //   trigger: '#RS-section-intro',
    //   start: 'top top',
    //   end: 'center top',
    //   animation: gsap.fromTo('.RS-section__bg-picture', {yPercent: 0, alpha: 1, scale: 1 }, {yPercent: -25, alpha: 0, scale: .98}),
    //   scrub: 1
    // });

    ScrollTrigger.create({
      trigger: '#RS-section-cta',
      start: 'top center',
      end: 'bottom top',
      animation: gsap.fromTo('#RS-section-cta', {yPercent: 0 }, {yPercent: 5}),
      scrub: 1
    });

  }

  const resizeObserver = new ResizeObserver(entries => {
    for (let entry of entries) {
      ScrollTrigger.refresh();
    }
  });

  resizeObserver.observe(document.body);

};